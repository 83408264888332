@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    font-family: "DM Sans", sans-serif;
  }
}

@layer components {
  .skeleton {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  .prose :where(thead):not(:where([class~="not-prose"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: #e2e8f0;
    color: #334155;
  }
  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }
  .prose :where(table):not(:where([class~="not-prose"] *)) {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .prose :where(thead th):not(:where([class~="not-prose"] *)) {
    color: #0f172a;
    font-weight: 600;
    vertical-align: bottom;
    padding: 0 0.5714286em 0.5714286em;
  }
  .prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }
  .prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }
  .prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: #f1f5f9;
  }
  .prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
    padding: 0.5714286em;
  }
  .prose :where(tbody td):not(:where([class~="not-prose"] *)) {
    vertical-align: initial;
  }
  .prose
    :where(tbody td:last-child, tfoot td:last-child):not(
      :where([class~="not-prose"] *)
    ) {
    padding-right: 0;
  }
  .prose :where(tbody td):not(:where([class~="not-prose"] *)) {
    vertical-align: initial;
  }
}
